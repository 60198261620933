import { GetServerSideProps } from 'next'
import { ContentfulBlock } from 'types'
import { getRenderedBlocks } from 'utils'

interface RouteMappingProps {
  [key: string]: string
}
// Re directing back to home since url's do not change.
const routeMapping: RouteMappingProps = {
  '/viewcategory.php?tag=Ergo-Ergo': '/',
  '/viewcategory.php?tag=House-Numbers': '/',
  '/viewcategory.php?tag=Umbrellas': '/',
  '/rugs?weavename=Livos': '/',
  '/viewcategory.php?tag=Cleaning-Tips': '/',
  '/viewcategory.php?tag=cleaning-tips': '/',
  '/viewcategory.php?tag=Spot-Removal': '/',
}

export interface TmGenericProps {
  blocks?: ContentfulBlock[]
}

export const TmGeneric = ({ blocks = [] }: TmGenericProps) => {
  return <>{getRenderedBlocks(blocks)}</>
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, res } = context
  const url = req.url?.split('?')[0] || ''
  const mappedURL = routeMapping[url]

  if (mappedURL) {
    const modifiedURL = removeQueryParams(mappedURL)
    res.setHeader('Location', modifiedURL)
    res.statusCode = 301
    res.end()
  }

  return {
    props: {},
  }
}

function removeQueryParams(url: string): string {
  const { pathname } = new URL(url)
  return pathname
}

export default TmGeneric
